<template>
	<v-card :tile="true" light>
		<v-row class="ma-0 pa-0 flex-nowrap justify-space-around">
			<v-col class="pa-0 ma-0">
				<v-card-title :style="{ wordBreak: 'normal' }" v-if="!title">
					{{ issueType.subject }}
				</v-card-title>
			</v-col>
			<v-col class="pa-0 ma-0 flex-grow-0">
				<v-btn
					@click="
						resetForm();
						$emit('close');
					"
					icon
					class="mt-4 mr-4"
					color="secondary"
					style="width: 32px; height: 32px"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-col>
		</v-row>

		<v-card-text v-if="issueType.subject.includes('Boka')">
			Skicka en förfrågan om att boka våra tjänster.
		</v-card-text>

		<v-card-text v-if="message && issueType.subject.includes('Avboka')">
			<p class="text-block">{{ message }}</p>
		</v-card-text>

		<v-form @submit="submit">
			<v-card-text v-if="title" class="py-0 my-0">
				<v-text-field
					v-model="titleContent"
					:error-messages="titleContentErrors"
					:placeholder="issueType.subject"
					:counter="maxLengthTitle"
					label="Rubrik"
					required
				/>
			</v-card-text>

			<v-card-text class="py-0 my-0">
				<v-textarea
					v-model="content"
					:error-messages="contentErrors"
					:counter="maxLength"
					label="Beskrivning"
					auto-grow
					no-resize
					placeholder="Beskriv ditt ärende..."
					required
				/>
			</v-card-text>

			<v-card-actions>
				<v-spacer />
				<v-btn
					@click="submit"
					:loading="loading"
					:disabled="loading"
					color="primary"
					large
				>
					Skicka meddelande
				</v-btn>
				<v-spacer />
			</v-card-actions>
		</v-form>
	</v-card>
</template>

<script>
import _ from 'lodash';
import { maxLength, minLength, requiredIf } from 'vuelidate/lib/validators';
import { ISSUE_CREATE, ISSUES } from '@/graphql';

export default {
	props: {
		issueType: {
			type: Object,
			default: () => ({
				icon: 'mdi-plus',
				color: 'secondary',
				subject: 'Nytt ärende',
			}),
		},
		// If true, the user is prompted for an issue subject and there is no title on the card.
		// Otherwise, the card title is determined by the issue type subject and the user cannot
		// specify the subject.
		title: {
			type: Boolean,
		},
		message: {
			type: String,
			default: () => null,
		},
	},
	data: () => ({
		content: '',
		titleContent: '',
		loading: false,
		minLengthTitle: 3,
		maxLengthTitle: 50,
		minLength: 10,
		maxLength: 2000,
	}),
	computed: {
		overlayWidth() {
			return window.innerWidth * 0.9 + 'px';
		},
		contentRequired() {
			// If the user must supply a title, they dont have to add content
			return !this.title;
		},
		titleContentErrors() {
			const errors = [];
			if (!this.$v.titleContent.$dirty) return errors;

			if (!this.$v.titleContent.required) {
				errors.push('Du måste ange en rubrik');
			}

			if (!this.$v.titleContent.minLength) {
				errors.push(
					`Rubriken måste vara längre än ${this.minLengthTitle} tecken`,
				);
			}

			if (!this.$v.titleContent.maxLength) {
				errors.push(
					`Rubriken måste vara kortare än ${this.maxLengthTitle} tecken`,
				);
			}

			return errors;
		},
		contentErrors() {
			const errors = [];
			if (!this.$v.content.$dirty) return errors;

			if (!this.$v.content.required) {
				errors.push('Du måste ange en beskrivning');
			}

			if (!this.$v.content.minLength) {
				errors.push(
					`Beskrivningen måste vara längre än ${this.minLength} tecken`,
				);
			}

			if (!this.$v.content.maxLength) {
				errors.push(
					`Beskrivningen måste vara kortare än ${this.maxLength} tecken`,
				);
			}

			return errors;
		},
	},
	methods: {
		resetForm() {
			this.titleContent = '';
			this.content = '';
			// Ensure error text does not persist between creations
			this.$v.$reset();
		},
		submit(event) {
			event.preventDefault();
			this.$v.$touch();
			this.createIssue();
		},
		createIssue() {
			if (!this.$v.$invalid) {
				const subject =
					this.titleContent.length > 0
						? this.titleContent
						: this.issueType.subject;
				const input = {
					subject,
					content: this.content,
					type: parseInt(this.issueType.id, 10),
				};
				this.loading = true;

				this.$apollo
					.mutate({
						mutation: ISSUE_CREATE,
						variables: { input },
						update: (store, { data: { createIssue } }) => {
							const data = store.readQuery({ query: ISSUES });
							data.issues.push(createIssue);
							store.writeQuery({ query: ISSUES, data });
						},
					})
					.then(() => {
						this.loading = false;
						this.resetForm();
						this.$emit('close');
						this.$toast.success('Meddelandet skickades');
					})
					.catch(error => {
						console.error(error);
						this.loading = false;
						this.$toast.error('Meddelandet kunde inte skickas');
					});
			}
		},
	},
	validations() {
		return {
			titleContent: {
				// The minLength validator will pass on an empty string, e.g. if title is false.
				// Thus it would be enough to just have the minLength validator, but this is more
				// readable.
				required: requiredIf(() => this.title),
				// We only care about this validator if this field is required.
				// Otherwise, provide the always-passing validator, i.e. the constant true function.
				minLength: this.title
					? minLength(this.minLengthTitle)
					: _.constant(true),
				maxLength: maxLength(this.maxLengthTitle),
			},
			content: {
				required: requiredIf(() => this.contentRequired),
				minLength: this.contentRequired
					? minLength(this.minLength)
					: _.constant(true),
				maxLength: maxLength(this.maxLength),
			},
		};
	},
};
</script>

<style>
.text-block {
	white-space: pre-line;
}
</style>
